// import { createMedia } from "@artsy/fresnel";
import { Visibility, Button, Image } from "semantic-ui-react";
import React, { useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import logo from "./img/logo.png";

export default function NavBar() {
  const { t } = useTranslation(["common", "users", "frontPage"]);

  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <Visibility>
      <Navbar
        className={colorChange ? "navbar colorChange" : "navbar"}
        variant="dark"
        expand="lg"
        fixed="top"
      >
        <Container fluid>
          <Navbar.Brand>
            <Image href="/" src={logo} width="175" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <Nav.Link href="/">
                {t("navbar.home", { ns: "frontPage" })}
              </Nav.Link>
              <NavDropdown
                title={t("navbar.products", { ns: "frontPage" })}
                id="basic-nav-dropdown"
                menuVariant="dark"
              >
                <NavDropdown.Item href="/Real-TimeLaborGuide">
                  {t("navbar_submenu.rtlg", { ns: "frontPage" })}
                </NavDropdown.Item>
                <NavDropdown.Item href="/Real-TimePro">
                  {t("navbar_submenu.rtp", { ns: "frontPage" })}
                </NavDropdown.Item>
                <NavDropdown.Item href="/AutomotiveExpert">
                  {t("navbar_submenu.ae", { ns: "frontPage" })}
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title={t("navbar.contact", { ns: "frontPage" })}
                id="basic-nav-dropdown"
                menuVariant="dark"
              >
                <NavDropdown.Item href="/ContactUs">
                  {t("navbar.contact", { ns: "frontPage" })}
                </NavDropdown.Item>
                <NavDropdown.Item href="/CorrectionsForm">
                  {t("navbar_submenu.corrections", { ns: "frontPage" })}
                </NavDropdown.Item>
                <NavDropdown.Item href="/FrequentlyAskedQuestions">
                  {t("navbar_submenu.faq", { ns: "frontPage" })}
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Button
              href="tel:8004870279"
              content={t("address.phone", { ns: "frontPage" })}
              className="RTP_button"
              primary
            />
            <Button
              href="https://secure.autolaborexperts.com/"
              content={t("buttons.login", { ns: "frontPage" })}
              color="black"
            />
            <Button
              href="https://secure.autolaborexperts.com/registration"
              className="RTLG_button header_button"
              primary
              content={t("buttons.start", { ns: "frontPage" })}
            />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Visibility>
  );
}
