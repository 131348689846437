import React from "react";
//import { useTranslation } from "react-i18next";
import "./comparisonCards.scss";
import FlipCard from "./FlipCard";
import RTLG_FC from "./img/RTLG_FlipCard.webp";
import RTP_FC from "./img/RTP_FlipCard.webp";
import AE_FC from "./img/AE_FlipCard.webp";
import RTLG_logo from "./img/real_time_labor_guide_logo1.png";
import RTP_logo from "./img/real_time_pro_logo1.png";
import AE_logo from "./img/autoexpert_logo1.png";
import RTLG_logo1 from "./img/real_time_labor_guide_logo2.png";
import AE_logo1 from "./img/autoexpert_logo2.png";


const cards = [
  {
    id: "1",
    variant: "hover",
    backgroundColor: "#013850",
    front: {
      title: "Hover",
      background: `url("${RTLG_FC}")`,
      image: `${RTLG_logo}`,
      list1: "Labor Guide",
      list2: "Package Jobs",
      list3: "Miscellaneous Charges",
      list4: "Unlimited Tickets",
      list5: "QuickVIN Decoding",
      list6: "Staff Management",
      price: "15/Month**",
    },
    back: {
      image: `${RTLG_logo1}`,
      list1: "You use a labor guide every day – whether it’s a book, computer program, or just what’s in your head from past jobs. Occasionally, those times are way off the mark. Our labor guide is accurate, inexpensive, and take lots of factors into account, like experience with the job, specialty tools needed, and vehicle condition. We understand your challenges and do our best to maximize your success. Either as your “go-to” guide or your second opinion, you need the Real-Time Labor Guide in your tool box!",
      destination: '/Real-TimeLaborGuide',
    },
  },
  {
    id: "2",
    variant: "hover",
    backgroundColor: "#232729",
    front: {
      title: "Hover",
      background: `url("${RTP_FC}")`,
      image: `${RTP_logo}`,
      list1: "All Real-Time Labor Guide",
      list2: "Features AND:",
      list3: "Customer History",
      list4: "Shop Reports",
      list5: "Technician Tracking",
      list6: "Parts Markup",
      price: "30/Month**",
    },
    back: {
      background: "",
      image: `${RTP_logo}`,
      list1: "As you become busier and more successful, you need new tools to help run your shop more efficiently and capture additional profit from your increased work load. You still need the Real-Time Labor Guide to give proper quotes, but you also need a way to manage customers and access the jobs and estimates you have given them in the past. You need reports to help spot trends and opportunities to grow your income. You need the tool you choose to be easy, reliable and secure. You need Real-Time Pro!",
      destination: '/Real-TimePro',
    },
  },
  {
    id: "3",
    variant: "hover",
    backgroundColor: "#003820",
    front: {
      title: "Hover",
      background: `url("${AE_FC}")`,
      image: `${AE_logo}`,
      list1: "All Real-Time Pro Features*",
      list2: "AND:",
      list3: "Inventory",
      list4: "Advanced Reporting",
      list5: "Accounts Receivable",
      list6: "Reminder Letters",
      list7: "Mailing Labels",
      price: "195/Year***",
    },
    back: {
      background: "",
      image: `${AE_logo1}`,
      list1: "You may have gotten into this business because you enjoy turning wrenches, but to run a successful shop you need to manage the business as well. Day-to-day operations can take up much of your time. While the labor guide and customer management from Real-Time Pro are great, you need more advanced shop management software. You need features like accounts receivable, technician tracking, automatic parts markup, inventory, etc. You need Automotive Expert!",
      destination: '/AutomotiveExpert',
    },
  },
];

export default function ComparisonCards() {
  //const { t } = useTranslation(["common", "users", "frontPage"]);

  return (
    <div className="flip-container">
      <div className="row h-100">
        <div className="col d-flex flex-column flex-md-row justify-content-around align-items-center">
          {cards.map((card) => (
            <FlipCard key={card.id} card={card} />
          ))}
        </div>
      </div>
    </div>
  );
}
