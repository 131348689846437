import React, { useEffect } from "react";
import {
  Container,
  Header,
  Form,
  Divider,
  Segment,
  List,
  Grid,
  Label,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./contact.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import * as Regex from "../regex.js";
import background from "./img/formBG.webp";
import { useHistory } from "react-router-dom";

export default function Contact() {
  const { t } = useTranslation(["common", "users", "frontPage", "errors"]);

  useEffect(() => {
    document.title = t("contact.header", {
      ns: "contact",
    });
  }, []);
  
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      name: "",
      phoneNumber: "",
      emailAddress: "",
      question: "",
      validation: "",
      type: "contactForm",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        t("ContactUs.required_Name", { ns: "errors" })
      ),

      phoneNumber: Yup.string()
        .required(t("ContactUs.required_PhoneNumber", { ns: "errors" }))
        .matches(
          Regex.PhoneNumberMatch,
          t("ContactUs.invalid_PhoneNumber", { ns: "errors" })
        ),

      emailAddress: Yup.string()
        .matches(
          Regex.EmailMatch,
          t("ContactUs.invalid_Email", { ns: "errors" })
        )
        .required(t("ContactUs.required_Email", { ns: "errors" })),

      question: Yup.string().required(
        t("ContactUs.required_Question", { ns: "errors" })
      ),

      validation: Yup.string().matches("^[1]{1,1}$", "Please check your input.").required(
        t("ContactUs.required_Validation", { ns: "errors" })
      ),
    }),
    onSubmit: (values) => {
      axios({
        method: "POST",
        headers: { "content-type": "application/json" },
        //url: window.location.protocol + process.env.REACT_APP_API_URL + "/Email/SendContactEmail",
        url: process.env.REACT_APP_API_URL + "/Email/SendContactEmail",
        data: JSON.stringify(values, null, 2),
      }).then((response) => {
        history.push("/ContactUs/ContactUsConfirm");
      })
      .catch((error) => console.log(error));
    },
  });
  return (
    <>
    <Segment 
      className="staticBG"
      style={{
        backgroundImage: `url("${background}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container className="contact-container overflow-auto overflow_form">
        <Container className="form_frost">
        <Header as="h1" className="FAQ_header_font">
          {t("contact.header", {
            ns: "contact",
          })}
        </Header>
        <Header as="h3">
          {t("contact.support", {
            ns: "contact",
          })}
        </Header>
        <Header as="h4">
          {t("contact.email_us", {
            ns: "contact",
          })}
        </Header>

      <Container>
      <Form onSubmit={formik.handleSubmit}>
        <Grid>
          <Grid.Row>
            <Form.Input
              name="name"
              onChange={formik.handleChange}
              values={formik.values.name}
              label={
                t("contact.name", {
                  ns: "contact",
                }) + ":"
              }
              placeholder={t("contact.name", {
                ns: "contact",
              })}
            />
            {formik.errors.name ? (
              <div className="row mb-2">
                <div className="col">
                  {formik.errors.name ? (
                    <Label basic color="red">
                      {formik.errors.name}
                    </Label>
                  ) : null}
                </div>
              </div>
            ) : null}
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Input
                name="phoneNumber"
                onChange={formik.handleChange}
                values={formik.values.phoneNumber}
                label={
                  t("contact.phone", {
                    ns: "contact",
                  }) + ":"
                }
                placeholder={t("contact.phone", {
                  ns: "contact",
                })}
              />
              {formik.errors.phoneNumber ? (
                <div className="row mb-2">
                  <div className="col">
                    {formik.errors.phoneNumber ? (
                      <Label basic color="red">
                        {formik.errors.phoneNumber}
                      </Label>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                name="emailAddress"
                onChange={formik.handleChange}
                values={formik.values.emailAddress}
                label={
                  t("contact.email", {
                    ns: "contact",
                  }) + ":"
                }
                placeholder={t("contact.email", {
                  ns: "contact",
                })}
              />
              {formik.errors.emailAddress ? (
                <div className="row mb-2">
                  <div className="col">
                    {formik.errors.emailAddress ? (
                      <Label basic color="red">
                        {formik.errors.emailAddress}
                      </Label>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Form.TextArea
              name="question"
              onChange={formik.handleChange}
              values={formik.values.question}
              label={
                t("contact.question", {
                  ns: "contact",
                }) + ":"
              }
              placeholder={t("contact.question", {
                ns: "contact",
              })}
            />
            {formik.errors.question ? (
              <div className="row mb-2">
                <div className="col">
                  {formik.errors.question ? (
                    <Label basic color="red">
                      {formik.errors.question}
                    </Label>
                  ) : null}
                </div>
              </div>
            ) : null}
          </Grid.Row>
          <Grid.Row>
            <Form.Input
              name="validation"
              onChange={formik.handleChange}
              values={formik.values.validation}
              label={
                t("contact.validation", {
                  ns: "contact",
                }) + ":"
              }
              placeholder={t("contact.validation", {
                ns: "contact",
              })}
            />
            {formik.errors.validation ? (
              <div className="row mb-2">
                <div className="col">
                  {formik.errors.validation ? (
                    <Label basic color="red">
                      {formik.errors.validation}
                    </Label>
                  ) : null}
                </div>
              </div>
            ) : null}
          </Grid.Row>
          <Grid.Row>
            <Form.Button type="submit" color="grey">
              {t("contact.submit_button", {
                ns: "contact",
              })}
            </Form.Button>
          </Grid.Row>
        </Grid>
      </Form>
      </Container>
      <Divider />
      <Segment basic className="FAQ_pt2">
        <Header as="h2" className="contact_center">
          {t("contact.other_methods", {
            ns: "contact",
          }) + ":"}
        </Header>
        <Segment basic className="FAQ_pt3">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={7}>
                <List floated="right">
                  <List.Item>
                    <List.Header>
                      {t("contact.mail", {
                        ns: "contact",
                      }) + ":"}
                    </List.Header>
                  </List.Item>
                  <List.Item>
                    {t("contact.RLTG", {
                      ns: "contact",
                    })}
                  </List.Item>
                  <List.Item>
                    {t("contact.po", {
                      ns: "contact",
                    })}
                  </List.Item>
                  <List.Item>
                    {t("contact.city_state", {
                      ns: "contact",
                    })}
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column floated="right" width={7}>
                <List floated="left">
                  <List.Item>
                    <List.Header>
                      {t("contact.phone_fax", {
                        ns: "contact",
                      }) + ":"}
                    </List.Header>
                  </List.Item>
                  <List.Item>
                    {t("contact.customer_support", {
                      ns: "contact",
                    }) + ": "}
                    <a href="tel:8004870279">(800) 487-0279</a>
                  </List.Item>
                  <List.Item>
                    {t("contact.fax", {
                      ns: "contact",
                    }) + ": "}
                    {t("contact.fax_number", {
                      ns: "contact",
                    })}
                  </List.Item>
                  <List.Item>
                    {t("contact.just_email", {
                      ns: "contact",
                    }) + ": "}
                    <a href="mailto:support@autolaborexperts.com?">
                      {t("contact.email_address", {
                        ns: "contact",
                      })}
                    </a>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment>
    </Container>
      </Container>
    </Segment>
    </>
  );
}
