import React, { useEffect } from "react";
import {
  Container,
  Header,
  Segment,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./contact.css";
import ConfirmBackground from "./img/confirm_page.webp";

export default function CorrectionsConfirm() {
  const { t } = useTranslation(["common", "users", "frontPage", "errors"]);

  useEffect(() => {
    document.title = t("confirmation.corrections", {
      ns: "contact",
    });
  }, []);

  return (
    <>
    <Segment 
      className="staticBG"
      style={{
        backgroundImage: `url("${ConfirmBackground}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container className="contact-container overflow-auto overflow_form">
        <Container className="form_frost confirmations">
        <Header textAlign="center" as="h2"> 
        {t("confirmation.corrections", {
                ns: "contact",
              })}
        </Header>
    </Container>
      </Container>
    </Segment>
    </>
  );
}
