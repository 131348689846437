import React, { Component } from "react";
import {
  Container,
  Header,
  Grid,
  Accordion,
  Icon,
  List
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";

class FAQAccordion extends Component {
  state = { activeIndex: -1 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { t } = this.props;
    const { activeIndex } = this.state;

    return (
      <Container className="product-container">
        <Accordion>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
            as="h3"
            className="homepage_header_font"
          >
            <Icon name="dropdown" />
            {t("FAQ_comparisons.free_trial_intro", {
              ns: "contact",
            }) + "?"}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3">
                    {t("FAQ_comparisons.online", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.free_trial_web", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.cd", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.free_trial_cd", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 1}
            index={1}
            onClick={this.handleClick}
            as="h3"
            className="homepage_header_font"
          >
            <Icon name="dropdown" />
            {t("FAQ_comparisons.find_parts_intro", {
              ns: "contact",
            })}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.online", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.find_parts_web", {
                      ns: "contact",
                    })}
                  </p>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.find_parts_nosuppliers", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.cd", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.find_parts_cd1", {
                      ns: "contact",
                    })}
                  </p>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.find_parts_cd2", {
                      ns: "contact",
                    })}
                  </p>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.find_parts_cd3", {
                      ns: "contact",
                    })}
                  </p>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.find_parts_nosuppliers", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 2}
            index={2}
            onClick={this.handleClick}
            as="h3"
            className="homepage_header_font"
          >
            <Icon name="dropdown" />
            {t("FAQ_comparisons.updates_intro", {
              ns: "contact",
            }) + "?"}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.online", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.update_web", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.cd", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.update_cd", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 3}
            index={3}
            onClick={this.handleClick}
            as="h3"
            className="homepage_header_font"
          >
            <Icon name="dropdown" />
            {t("FAQ_comparisons.add_users_intro", {
              ns: "contact",
            }) + "?"}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 3}>
            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.online", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.add_users_web1", {
                      ns: "contact",
                    })}
                  </p>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.add_users_web2", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.cd", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.add_users_cd1", {
                      ns: "contact",
                    })}
                  </p>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.add_users_cd2", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 4}
            index={4}
            onClick={this.handleClick}
            as="h3"
            className="homepage_header_font"
          >
            <Icon name="dropdown" />
            {t("FAQ_comparisons.install_intro", {
              ns: "contact",
            }) + "?"}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 4}>
            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.online", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.install_web", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.cd", {
                      ns: "contact",
                    })}
                  </Header>
                  <List bulleted>
                    <List.Item>
                    <p className="homepage_text">
                    {t("FAQ_comparisons.install_cd1", {
                      ns: "contact",
                    })}
                  </p>
                    </List.Item>
                    <List.Item>
                    <p className="homepage_text">
                    {t("FAQ_comparisons.install_cd2", {
                      ns: "contact",
                    })}
                  </p>
                    </List.Item>
                    <List.Item>
                    <p className="homepage_text">
                    {t("FAQ_comparisons.install_cd3", {
                      ns: "contact",
                    })}
                  </p>
                    </List.Item>
                  </List>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.install_cd4", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 5}
            index={5}
            onClick={this.handleClick}
            as="h3"
            className="homepage_header_font"
          >
            <Icon name="dropdown" />
            {t("FAQ_comparisons.setup_intro", {
              ns: "contact",
            }) + "?"}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 5}>
            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.online", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.setup_web1", {
                      ns: "contact",
                    })}
                  </p>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.setup_web2", {
                      ns: "contact",
                    })}
                  </p>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.setup_web3", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.cd", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.setup_cd", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 6}
            index={6}
            onClick={this.handleClick}
            as="h3"
            className="homepage_header_font"
          >
            <Icon name="dropdown" />
            {t("FAQ_comparisons.new_users/computers_intro", {
              ns: "contact",
            }) + "?"}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 6}>
            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.online", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.new_users/computers_web1", {
                      ns: "contact",
                    })}
                  </p>
                  <p className="homepage_text">
                    ***{t("FAQ_comparisons.new_users/computers_web2", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.cd", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.new_users/computers_cd1", {
                      ns: "contact",
                    })}
                  </p>
                  <p className="homepage_text">
                    ***{t("FAQ_comparisons.new_users/computers_cd2", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 7}
            index={7}
            onClick={this.handleClick}
            as="h3"
            className="homepage_header_font"
          >
            <Icon name="dropdown" />
            {t("FAQ_comparisons.new_comp_intro", {
              ns: "contact",
            }) + "?"}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 7}>
            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.online", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.new_comp_web", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.cd", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.new_comp_cd", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 8}
            index={8}
            onClick={this.handleClick}
            as="h3"
            className="homepage_header_font"
          >
            <Icon name="dropdown" />
            {t("FAQ_comparisons.system_requirements_intro", {
              ns: "contact",
            }) + "?"}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 8}>
            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.online", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.system_requirements_web1", {
                      ns: "contact",
                    })}
                  </p>
                  <List bulleted>
                    <List.Item>
                    <p className="homepage_text">
                    {t("FAQ_comparisons.system_requirements_web2", {
                      ns: "contact",
                    })}
                  </p>
                    </List.Item>
                    <List.Item>
                    <p className="homepage_text">
                    {t("FAQ_comparisons.system_requirements_web3", {
                      ns: "contact",
                    })}
                  </p>
                    </List.Item>
                    <List.Item>
                    <p className="homepage_text">
                    {t("FAQ_comparisons.system_requirements_web4", {
                      ns: "contact",
                    })}
                  </p>
                    </List.Item>
                    <List.Item>
                    <p className="homepage_text">
                    {t("FAQ_comparisons.system_requirements_web5", {
                      ns: "contact",
                    })}
                  </p>
                    </List.Item>
                    <List.Item>
                    <p className="homepage_text">
                    {t("FAQ_comparisons.system_requirements_web7", {
                      ns: "contact",
                    })}
                  </p>
                    </List.Item>
                  </List>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.system_requirements_web8", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.cd", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.system_requirements_cd1", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>


          <Accordion.Title
            active={activeIndex === 9}
            index={9}
            onClick={this.handleClick}
            as="h3"
            className="homepage_header_font"
          >
            <Icon name="dropdown" />
            {t("FAQ_comparisons.password_intro", {
              ns: "contact",
            }) + "!"}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 9}>
            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.online", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.password_web", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.cd", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.password_cd", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>


          <Accordion.Title
            active={activeIndex === 10}
            index={10}
            onClick={this.handleClick}
            as="h3"
            className="homepage_header_font"
          >
            <Icon name="dropdown" />
            {t("FAQ_comparisons.username_intro", {
              ns: "contact",
            }) + "?"}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 10}>
            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.online", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.username_web", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.cd", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.username_cd", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 11}
            index={11}
            onClick={this.handleClick}
            as="h3"
            className="homepage_header_font"
          >
            <Icon name="dropdown" />
            {t("FAQ_comparisons.errors_intro", {
              ns: "contact",
            }) + "?"}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 11}>
            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.online", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.errors_web1", {
                      ns: "contact",
                    })}
                  </p>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.errors_web2", {
                      ns: "contact",
                    })}
                  </p>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.errors_web3", {
                      ns: "contact",
                    })}
                  </p>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.errors_web4", {
                      ns: "contact",
                    })}
                  </p>
                  <p className="homepage_text">
                    {t("FAQ_comparisons.errors_web5", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
                <Grid.Column>
                <Header as="h3">
                    {t("FAQ_comparisons.cd", {
                      ns: "contact",
                    })}
                  </Header>
                  <p className="homepage_text">
                   <b>{t("FAQ_comparisons.errors_cd1", {
                      ns: "contact",
                    }) + ": "}</b> 
                    {t("FAQ_comparisons.errors_cd2", {
                      ns: "contact",
                    })}
                  </p>
                  <p className="homepage_text">
                   <b>{t("FAQ_comparisons.errors_cd3", {
                      ns: "contact",
                    }) + ": "}</b> 
                    {t("FAQ_comparisons.errors_cd4", {
                      ns: "contact",
                    })}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>
        </Accordion>
      </Container>
    );
  }
}

export default withTranslation()(FAQAccordion);
