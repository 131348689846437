import React, { useEffect } from "react";
import {
  Container,
  Header,
  Form,
  Segment,
  Divider,
  Radio,
  Select,
  Label,
  Grid,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./contact.css";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import * as Regex from "../regex.js";
import CorrectionFormEmail from "../../app/correctionDto";
import background from "./img/formBG.webp";
import { useHistory } from "react-router-dom";

const countryOptions = [
  { text: "United States", value: "US", key: "US" },
  { text: "Canada", value: "CN", key: "CN" },
];

export default function Correction() {
  const { t } = useTranslation(["common", "users", "frontPage"]);

  useEffect(() => {
    document.title = t("corrections.header", {
      ns: "contact",
    });
  }, []);

  const history = useHistory();
  const mydata = new CorrectionFormEmail();

  const formik = useFormik({
    initialValues: { mydata },
    validationSchema: Yup.object({
      year: Yup.string().required(
        t("Corrections.required_year", { ns: "errors" })
      ),
      make: Yup.string().required(
        t("Corrections.required_make", { ns: "errors" })
      ),
      model: Yup.string().required(
        t("Corrections.required_model", { ns: "errors" })
      ),
      engineSize: Yup.string().required(
        t("Corrections.required_engineSize", { ns: "errors" })
      ),
      jobDescription: Yup.string().required(
        t("Corrections.required_jobDescription", { ns: "errors" })
      ),
      high: Yup.string().required(
        t("Corrections.required_high", { ns: "errors" })
      ),
      average: Yup.string().required(
        t("Corrections.required_average", { ns: "errors" })
      ),
      low: Yup.string().required(
        t("Corrections.required_low", { ns: "errors" })
      ),
      yourTime: Yup.string().required(
        t("Corrections.required_yourTime", { ns: "errors" })
      ),
      comments: Yup.string().required(
        t("Corrections.required_comments", { ns: "errors" })
      ),
      shopName: Yup.string().required(
        t("Corrections.required_shopName", { ns: "errors" })
      ),
      firstName: Yup.string().required(
        t("Corrections.required_firstName", { ns: "errors" })
      ),
      lastName: Yup.string().required(
        t("Corrections.required_lastName", { ns: "errors" })
      ),
      address: Yup.string().required(
        t("Corrections.required_address", { ns: "errors" })
      ),
      city: Yup.string().required(
        t("Corrections.required_city", { ns: "errors" })
      ),
      state: Yup.string().required(
        t("Corrections.required_state", { ns: "errors" })
      ),
      zip: Yup.string().required(
        t("Corrections.required_zip", { ns: "errors" })
      ),
      country: Yup.string().required(
        t("Corrections.required_country", { ns: "errors" })
      ),
      phoneNumber: Yup.string()
        .matches(
          Regex.PhoneNumberMatch,
          t("ContactUs.invalid_PhoneNumber", { ns: "errors" })
        )
        .required(t("Corrections.required_phoneNumber", { ns: "errors" })),
      emailAddress: Yup.string()
        .matches(
          Regex.EmailMatch,
          t("ContactUs.invalid_Email", { ns: "errors" })
        )
        .required(t("Corrections.required_emailAddress", { ns: "errors" })),
      currentCustomer: Yup.string().required(
        t("Corrections.required_currentCustomer", { ns: "errors" })
      ),
    }),
    onSubmit: (values) => {
      axios({
        method: "POST",
        headers: { "content-type": "application/json" },
        //url: window.location.protocol + process.env.REACT_APP_API_URL + "/Email/sendcorrectionemail",
        url: process.env.REACT_APP_API_URL + "/Email/sendcorrectionemail",
        data: JSON.stringify(values, null, 2),
      })
        .then((response) => {
          history.push("/CorrectionsForm/CorrectionsConfirm");
        })
        .catch((error) => console.log(error));
    },
  });

  return (
    <>
      <Segment
        className="staticBG"
        style={{
          backgroundImage: `url("${background}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container className="contact-container overflow-auto overflow_form">
          <Container className="form_frost ">
            <Header as="h1" className="FAQ_header_font">
              {t("corrections.header", {
                ns: "contact",
              })}
            </Header>
            <Segment basic>
              <p>
                {t("corrections.header_p1", {
                  ns: "contact",
                })}
              </p>
              <p>
                {t("corrections.header_p2", {
                  ns: "contact",
                })}
              </p>
              <p>
                {t("corrections.header_p3", {
                  ns: "contact",
                })}
              </p>
            </Segment>
            <Divider />
            <Header as="h2">
              {t("corrections.info", {
                ns: "contact",
              })}
            </Header>
            <Container>
              <Form
                onSubmit={formik.handleSubmit}
                onChange={formik.handleChange}
              >
                <Grid stackable>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form.Input
                        name="year"
                        onChange={formik.handleChange}
                        values={formik.values.year}
                        label={
                          t("corrections.year", {
                            ns: "contact",
                          }) + ": *"
                        }
                        placeholder={t("corrections.year", {
                          ns: "contact",
                        })}
                      />
                      {formik.errors.year ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.year ? (
                              <Label basic color="red">
                                {formik.errors.year}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        name="make"
                        onChange={formik.handleChange}
                        values={formik.values.make}
                        label={
                          t("corrections.make", {
                            ns: "contact",
                          }) + ": *"
                        }
                        placeholder={t("corrections.make", {
                          ns: "contact",
                        })}
                      />
                      {formik.errors.make ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.make ? (
                              <Label basic color="red">
                                {formik.errors.make}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form.Input
                        name="model"
                        onChange={formik.handleChange}
                        values={formik.values.model}
                        label={
                          t("corrections.model", {
                            ns: "contact",
                          }) + ": *"
                        }
                        placeholder={t("corrections.model", {
                          ns: "contact",
                        })}
                      />
                      {formik.errors.model ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.model ? (
                              <Label basic color="red">
                                {formik.errors.model}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        name="engineSize"
                        onChange={formik.handleChange}
                        values={formik.values.engineSize}
                        label={
                          t("corrections.engine", {
                            ns: "contact",
                          }) + ": *"
                        }
                        placeholder={t("corrections.engine", {
                          ns: "contact",
                        })}
                      />
                      {formik.errors.engineSize ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.engineSize ? (
                              <Label basic color="red">
                                {formik.errors.engineSize}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Form.Input
                      name="jobDescription"
                      onChange={formik.handleChange}
                      values={formik.values.jobDescription}
                      label={
                        t("corrections.desc", {
                          ns: "contact",
                        }) + ": *"
                      }
                      placeholder={t("corrections.desc", {
                        ns: "contact",
                      })}
                    />
                    {formik.errors.jobDescription ? (
                      <div className="row mb-2">
                        <div className="col">
                          {formik.errors.jobDescription ? (
                            <Label basic color="red">
                              {formik.errors.jobDescription}
                            </Label>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </Grid.Row>
                  </Grid>
                    <Header as="h4">
                      {t("corrections.current_times", {
                        ns: "contact",
                      }) + ":"}
                    </Header>
                  <Grid>
                  <Grid.Row columns={3}>
                    <Grid.Column>
                      <Form.Input
                        name="high"
                        onChange={formik.handleChange}
                        values={formik.values.high}
                        label={
                          t("corrections.high", {
                            ns: "contact",
                          }) + ": *"
                        }
                        placeholder={t("corrections.high", {
                          ns: "contact",
                        })}
                      />

                      {formik.errors.high ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.high ? (
                              <Label basic color="red">
                                {formik.errors.high}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        name="average"
                        onChange={formik.handleChange}
                        values={formik.values.average}
                        label={
                          t("corrections.avg", {
                            ns: "contact",
                          }) + ": *"
                        }
                        placeholder={t("corrections.avg", {
                          ns: "contact",
                        })}
                      />
                      {formik.errors.average ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.average ? (
                              <Label basic color="red">
                                {formik.errors.average}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        name="low"
                        onChange={formik.handleChange}
                        values={formik.values.low}
                        label={
                          t("corrections.low", {
                            ns: "contact",
                          }) + ": *"
                        }
                        placeholder={t("corrections.low", {
                          ns: "contact",
                        })}
                      />
                      {formik.errors.low ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.low ? (
                              <Label basic color="red">
                                {formik.errors.low}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Form.Input
                      name="yourTime"
                      onChange={formik.handleChange}
                      values={formik.values.yourTime}
                      label={
                        t("corrections.new_time", {
                          ns: "contact",
                        }) + ": *"
                      }
                      placeholder={t("corrections.new_time", {
                        ns: "contact",
                      })}
                    />
                    {formik.errors.yourTime ? (
                      <div className="row mb-2">
                        <div className="col">
                          {formik.errors.yourTime ? (
                            <Label basic color="red">
                              {formik.errors.yourTime}
                            </Label>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </Grid.Row>
                  <Grid.Row>
                    <Form.TextArea
                      name="comments"
                      onChange={formik.handleChange}
                      values={formik.values.comments}
                      label={
                        t("corrections.comment", {
                          ns: "contact",
                        }) + ": *"
                      }
                      placeholder={t("corrections.comment", {
                        ns: "contact",
                      })}
                    />
                    {formik.errors.comments ? (
                      <div className="row mb-2">
                        <div className="col">
                          {formik.errors.comments ? (
                            <Label basic color="red">
                              {formik.errors.comments}
                            </Label>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </Grid.Row>
                  </Grid>
                    <Divider />
                    <Header as="h2">
                      {t("corrections.contact", {
                        ns: "contact",
                      })}
                    </Header>
                  <Grid>
                  <Grid.Row>
                    <Form.Input
                      name="shopName"
                      onChange={formik.handleChange}
                      values={formik.values.shopName}
                      label={
                        t("contact.shop", {
                          ns: "contact",
                        }) + ": *"
                      }
                      placeholder={t("contact.shop", {
                        ns: "contact",
                      })}
                    />
                    {formik.errors.shopName ? (
                      <div className="row mb-2">
                        <div className="col">
                          {formik.errors.shopName ? (
                            <Label basic color="red">
                              {formik.errors.shopName}
                            </Label>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Header as="h4" className="name">
                        {t("contact.name", {
                          ns: "contact",
                        }) + ": *"}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form.Input
                        name="firstName"
                        onChange={formik.handleChange}
                        values={formik.values.firstName}
                        placeholder={t("contact.first", {
                          ns: "contact",
                        })}
                      />
                      {formik.errors.firstName ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.firstName ? (
                              <Label basic color="red">
                                {formik.errors.firstName}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        name="lastName"
                        onChange={formik.handleChange}
                        values={formik.values.lastName}
                        placeholder={t("contact.last", {
                          ns: "contact",
                        })}
                      />
                      {formik.errors.lastName ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.lastName ? (
                              <Label basic color="red">
                                {formik.errors.lastName}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Header as="h4" className="name">
                        {t("contact.shop_address", {
                          ns: "contact",
                        }) + ": *"}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Form.Input
                      name="address"
                      onChange={formik.handleChange}
                      values={formik.values.address}
                      placeholder={t("contact.addy", {
                        ns: "contact",
                      })}
                    />
                    {formik.errors.address ? (
                      <div className="row mb-2">
                        <div className="col">
                          {formik.errors.address ? (
                            <Label basic color="red">
                              {formik.errors.address}
                            </Label>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form.Input
                        name="city"
                        onChange={formik.handleChange}
                        values={formik.values.city}
                        placeholder={t("contact.city", {
                          ns: "contact",
                        })}
                      />
                      {formik.errors.city ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.city ? (
                              <Label basic color="red">
                                {formik.errors.city}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        name="state"
                        onChange={formik.handleChange}
                        values={formik.values.state}
                        placeholder={t("contact.state", {
                          ns: "contact",
                        })}
                      />
                      {formik.errors.state ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.state ? (
                              <Label basic color="red">
                                {formik.errors.state}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form.Input
                        name="zip"
                        onChange={formik.handleChange}
                        values={formik.values.zip}
                        placeholder={t("contact.zip", {
                          ns: "contact",
                        })}
                      />
                      {formik.errors.zip ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.zip ? (
                              <Label basic color="red">
                                {formik.errors.zip}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <Select
                          id="country"
                          name="country"
                          onChange={(e, data) => {
                            formik.handleChange(e);
                            formik.setFieldValue("country", data.value);
                          }}
                          //value={formik.values.country}
                          values={formik.values.country}
                          options={countryOptions}
                          label={t("contact.Country", {
                            ns: "contact",
                          })}
                          placeholder="Select your country"
                        />
                      </Form.Field>
                      {formik.errors.country ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.country ? (
                              <Label basic color="red">
                                {formik.errors.country}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form.Input
                        name="phoneNumber"
                        onChange={formik.handleChange}
                        values={formik.values.phoneNumber}
                        label={
                          t("contact.phone", {
                            ns: "contact",
                          }) + ": *"
                        }
                        placeholder={t("contact.phone", {
                          ns: "contact",
                        })}
                      />
                      {formik.errors.phoneNumber ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.phoneNumber ? (
                              <Label basic color="red">
                                {formik.errors.phoneNumber}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        name="emailAddress"
                        onChange={formik.handleChange}
                        values={formik.values.emailAddress}
                        label={
                          t("contact.email", {
                            ns: "contact",
                          }) + ": *"
                        }
                        placeholder={t("contact.email", {
                          ns: "contact",
                        })}
                      />
                      {formik.errors.emailAddress ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.emailAddress ? (
                              <Label basic color="red">
                                {formik.errors.emailAddress}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Form.Group inline>
                      <label>
                        {t("corrections.customer_status", {
                          ns: "contact",
                        }) + ": *"}
                      </label>
                      <Form.Field>
                        <Radio
                          id="currentCustomer1"
                          name="currentCustomer"
                          onChange={formik.handleChange}
                          label={t("corrections.yes", {
                            ns: "contact",
                          })}
                          values={formik.values.currentCustomer}
                          value="yes"
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          id="currentCustomer2"
                          name="currentCustomer"
                          onChange={formik.handleChange}
                          label={t("corrections.no", {
                            ns: "contact",
                          })}
                          values={formik.values.currentCustomer}
                          value="no"
                        />
                      </Form.Field>
                      {formik.errors.currentCustomer ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.currentCustomer ? (
                              <Label basic color="red">
                                {formik.errors.currentCustomer}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Form.Group>
                  </Grid.Row>
                  <Grid.Row>
                    <Form.Button type="submit" color="grey">
                      {t("corrections.submit", {
                        ns: "contact",
                      })}
                    </Form.Button>
                  </Grid.Row>
                </Grid>
              </Form>
            </Container>
          </Container>
        </Container>
      </Segment>
    </>
  );
}
