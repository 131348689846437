import React, { useEffect } from "react";
import {
  Container,
  Header,
  Divider,
  Grid,
  Segment,
  List,
  Card,
  Button,
  Image
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./products.css";
import AEAccordion from "./AEAccordion";
import impact from "./img/impact-AE.webp";
import pic1 from "./img/AE1.webp";
import pic2 from "./img/AE2.webp";
import tools from "./img/tools.webp";

import "react-app-protect/dist/index.css";

export default function Expert() {
  const { t } = useTranslation(["common", "users", "frontPage"]);

  useEffect(() => {
    document.title = t("AE.header", {
      ns: "products",
    });
  }, []);

  return (
    <>
      <Container className="product-container">
        <Header as="h1" className="FAQ_header_font">
          {t("AE.header", {
            ns: "products",
          })}
        </Header>
        <Header as="h3">
          {t("AE.sub_header", {
            ns: "products",
          })}
        </Header>
        <Divider />
        <p className="homepage_text">
          {t("AE.p1", {
            ns: "products",
          })}
        </p>
        <p className="homepage_text">
          {t("AE.p2", {
            ns: "products",
          })}
        </p>
        <Segment basic>
          <Card
            fluid
            className="sign_up_card_AE"
            style={{
              backgroundImage: `url("${impact}")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Grid container textAlign="center" stackable verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={8}>
                  <Header as="h1" className="card_header_font">
                    {t("RTLG.only", {
                      ns: "products",
                    })}{" "}
                    $195.00/
                    {t("AE.year", {
                      ns: "products",
                    })}
                    {"!"}
                  </Header>
                  <Header as="h5" className="extra_footer_AE no_margin">
                    *$495.00/
                    {t("AE.first_year", {
                      ns: "products",
                    })}
                    {"."}
                  </Header>
                  <div className="card_button">
                  <Button className="AE_button" primary href="/AutomotiveExpert/FreeTrial">
                    {t("button.free_trial", {
                      ns: "products",
                    })}
                  </Button>
                  <p>
                    {t("AE.45_days", {
                      ns: "products",
                    })}
                  </p>
                  </div>
                </Grid.Column>
                <Grid.Column floated="right" width={6} className="cardFinePrint">
                  {/* <Image bordered rounded size="large" src={wrench} /> */}
                  <p className="finePrint">
                    {t("RTLG.check", {
                      ns: "products",
                    })}
                    <a href="/FrequentlyAskedQuestions">
                      {t("RTLG.FAQ_page", {
                        ns: "products",
                      })}
                    </a>
                    {t("RTLG.more_info", {
                      ns: "products",
                    })}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card>
        </Segment>
        <Segment basic className="features_intro">
          <Header as="h1" textAlign="center">
            {t("AE.features", {
              ns: "products",
            })}
            {":"}
          </Header>
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as="h2">
                  {t("AE.discover", {
                    ns: "products",
                  }) + ":"}
                </Header>
                <List relaxed="very" className="homepage_text">
                  {/* <List.Item
                    icon="check"
                    content={t("RTLG.access", {
                      ns: "products",
                    })}
                  /> */}
                  <List.Item
                    icon="check"
                    content={t("AE.inventory", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("AE.adv_reporting", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("AE.acc_receivable", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("AE.reminder_letters", {
                      ns: "products",
                    })}
                  />
                  <List.Item
                    icon="check"
                    content={t("AE.mailing_labels", {
                      ns: "products",
                    })}
                  />
                </List>
              </Grid.Column>
              <Grid.Column floated="right" width={6}>
              <Image bordered rounded fluid src={tools} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment basic className="features2">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={6}>
              <Image bordered rounded fluid src={pic2} />
              </Grid.Column>
              <Grid.Column floated="right" width={8}>
                <Header as="h3" className="homepage_header_font"></Header>
                <List relaxed="very" className="homepage_text">
                  <List.Item icon="check">
                    <b>
                      {t("RTLG.pricing_intro", {
                        ns: "products",
                      }) + ": "}
                    </b>
                    {t("RTLG.pricing", {
                      ns: "products",
                    })}{" "}
                  </List.Item>
                  <List.Item icon="check">
                    <b>
                      {t("RTLG.easy_intro", {
                        ns: "products",
                      }) + ": "}
                    </b>
                    {t("RTLG.easy", {
                      ns: "products",
                    })}{" "}
                  </List.Item>
                  <List.Item icon="check">
                    <b>
                      {t("RTLG.resource_intro", {
                        ns: "products",
                      }) + ": "}
                    </b>
                    {t("RTLG.resource", {
                      ns: "products",
                    })}{" "}
                  </List.Item>
                  {/* <List.Item icon="check">
                    <b>
                      {t("RTLG.customize_features_intro", {
                        ns: "products",
                      }) + ": "}
                    </b>
                    {t("RTLG.customize_features", {
                      ns: "products",
                    })}{" "}
                  </List.Item> */}
                  <List.Item icon="check">
                    <b>
                      {t("AE.cd", {
                        ns: "products",
                      }) + ": "}
                    </b>
                    {t("AE.cd_blurb", {
                      ns: "products",
                    })}{" "}
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment basic className="sign_up_card">
          <AEAccordion />
        </Segment>
      </Container>
      <Container textAlign="center" className="extra_footer_AE"
      style={{
        backgroundImage: `url("${pic1}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}>
        <Segment basic>
          <Header as="h1" className="extra_footer_AE no_bottom_margin">
            {t("RTLG.only", {
              ns: "products",
            })}{" "}
            $195.00/
            {t("AE.year", {
              ns: "products",
            })}
            {"!"}
          </Header>
          <Header as="h5" className="extra_footer_AE no_margin">
            *$495.00/
            {t("AE.first_year", {
              ns: "products",
            })}
            {"."}
          </Header>
          <Header as="h3" className="extra_footer_AE">
            {t("button.2_mins", {
              ns: "products",
            })}
          </Header>
          <Button className="AE_button" primary href="/AutomotiveExpert/FreeTrial">
            {t("button.45_free_trial", {
              ns: "products",
            })}
          </Button>
          <br></br>
          <p>
            {t("button.no_cc", {
              ns: "products",
            })}
          </p>
          <p className="finePrint">
            {t("RTLG.check", {
              ns: "products",
            })}
            <a href="/FrequentlyAskedQuestions">
              {t("RTLG.FAQ_page", {
                ns: "products",
              })}
            </a>
            {t("RTLG.more_info", {
              ns: "products",
            })}
          </p>
        </Segment>
      </Container>
    </>
  );
}
