export default class CorrectionFormEmail {
  shopName = "";
  firstName = "";
  lastName = "";
  phoneNumber = "";
  emailAddress = "";
  address = "";
  city = "";
  state = "";
  zip = "";
  country = "US";
  currentCustomer = "";
  year = "";
  make = "";
  model = "";
  engineSize = "";
  jobDescription = "";
  high = "";
  average = "";
  low = "";
  yourTime = "";
  comments = "";

  constructor(
    shopName,
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
    address,
    city,
    state,
    zip,
    country,
    currentCustomer,
    year,
    make,
    model,
    engineSize,
    jobDescription,
    high,
    average,
    low,
    yourTime,
    comments
  ) {
    this.shopName = shopName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
    this.emailAddress = emailAddress;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.country = country;
    this.currentCustomer = currentCustomer;
    this.year = year;
    this.make = make;
    this.model = model;
    this.engineSize = engineSize;
    this.jobDescription = jobDescription;
    this.high = high;
    this.average = average;
    this.low = low;
    this.yourTime = yourTime;
    this.comments = comments;
  }
}
