import React, { useEffect } from "react";
import {
  Container,
  Header,
  Segment,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./products.css";
import ConfirmBackground from "./img/confirm_page.webp";

export default function AEformConfirm() {
  const { t } = useTranslation(["common", "users", "contact", "errors"]);

  useEffect(() => {
    document.title =t("confirmation.AE1", {
      ns: "contact",
    });
  }, []);

  return (
    <>
    <Segment 
      className="staticBG"
      style={{
        backgroundImage: `url("${ConfirmBackground}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container className="contact-container overflow-auto overflow_form">
        <Container className="form_frost confirmations">
        <Header textAlign="center" as="h2"> 
        {t("confirmation.AE1", {
                ns: "contact",
              })}<a href="mailto:support@autolaborexperts.com?"> support@autolaborexperts.com. </a>
        </Header>
        <Header textAlign="center" as="h2">
        {t("confirmation.AE2", {
                ns: "contact",
              })} <a href="tel:8004870279"> (800) 487-0279. </a>{t("confirmation.AE3", {
                ns: "contact",
              })}
        </Header>
    </Container>
      </Container>
    </Segment>
    </>
  );
}