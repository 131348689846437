import React, { useEffect } from "react";
import { Container, Header, List } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function Privacy() {
  const { t } = useTranslation(["common", "users", "contact"]);

  useEffect(() => {
    document.title = t("privacy_statement.header", { ns: "contact" });
  }, []);

  return (
    <Container className="product-container">
      <Header as="h1">
        {t("privacy_statement.header", { ns: "contact" })}
      </Header>

      {/* Intro and Scope */}
      <Header as="h3">{t("privacy_statement.scope", { ns: "contact" })}</Header>
      <p>{t("privacy_statement.scope_p1", { ns: "contact" })}</p>

      {/* Collection of your Personal Information  */}
      <Header as="h3">{t("privacy_statement.info", { ns: "contact" })}</Header>
      <p>{t("privacy_statement.info_p1", { ns: "contact" }) + ":"}</p>
      <List>
        <List.Item
          icon="check"
          content={t("privacy_statement.info_p2", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.info_p3", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.info_p4", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.info_p5", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.info_p6", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.info_p7", { ns: "contact" })}
        />
      </List>
      <p>{t("privacy_statement.info_p8", { ns: "contact" })}</p>
      <p>{t("privacy_statement.info_p9", { ns: "contact" })}</p>

      {/* Use of your Personal Information  */}
      <Header as="h3">
        {t("privacy_statement.personal_info", { ns: "contact" })}
      </Header>
      <p>{t("privacy_statement.personal_info_p1", { ns: "contact" })}</p>
      <List>
        <List.Item
          icon="check"
          content={t("privacy_statement.personal_info_p2", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.personal_info_p3", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.personal_info_p4", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.personal_info_p5", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.personal_info_p6", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.personal_info_p7", { ns: "contact" })}
        />
      </List>
      <p>{t("privacy_statement.personal_info_p8", { ns: "contact" })}</p>
      <p>{t("privacy_statement.personal_info_p9", { ns: "contact" })}</p>

      {/* Sharing Information with Third Parties  */}
      <Header as="h3">
        {t("privacy_statement.3rd_parties_intro", { ns: "contact" })}
      </Header>
      <p>{t("privacy_statement.3rd_parties_p1", { ns: "contact" })}</p>
      <p>{t("privacy_statement.3rd_parties_p2", { ns: "contact" })}</p>
      <p>{t("privacy_statement.3rd_parties_p3", { ns: "contact" })}</p>

      {/* Opt-Out of Disclosure of Personal Information to Third Parties  */}
      <Header as="h3">
        {t("privacy_statement.disclose", { ns: "contact" })}
      </Header>
      <p>{t("privacy_statement.disclose_p1", { ns: "contact" })}</p>
      <List>
        <List.Item
          icon="check"
          content={t("privacy_statement.disclose_p2", { ns: "contact" })}
        />
      </List>
      <p>{t("privacy_statement.disclose_p3", { ns: "contact" })}</p>

      {/* Tracking User Behavior  */}
      <Header as="h3">
        {t("privacy_statement.tracking_user_behavior_intro", { ns: "contact" })}
      </Header>
      <p>
        {t("privacy_statement.tracking_user_behavior_p1", { ns: "contact" })}
      </p>

      {/* Automatically Collected Information */}
      <Header as="h3">
        {t("privacy_statement.auto_collected_info", { ns: "contact" })}
      </Header>
      <p>{t("privacy_statement.auto_collected_info_p1", { ns: "contact" })}</p>

      {/* Cookies */}
      <Header as="h3">
        {t("privacy_statement.cookies", { ns: "contact" })}
      </Header>
      <p>{t("privacy_statement.cookies_p1", { ns: "contact" })}</p>
      <p>{t("privacy_statement.cookies_p2", { ns: "contact" })}</p>
      <p>{t("privacy_statement.cookies_p3", { ns: "contact" })}</p>

      {/* Links */}
      <Header as="h3">{t("privacy_statement.links", { ns: "contact" })}</Header>
      <p>{t("privacy_statement.links_p1", { ns: "contact" })}</p>

      {/* Security of your Personal Information */}
      <Header as="h3">
        {t("privacy_statement.secure_info", { ns: "contact" })}
      </Header>
      <p>{t("privacy_statement.secure_info_p1", { ns: "contact" })}</p>
      <List>
        <List.Item
          icon="check"
          content={t("privacy_statement.secure_info_p2", { ns: "contact" })}
        />
      </List>
      <p>{t("privacy_statement.secure_info_p3", { ns: "contact" })}</p>
      <p>{t("privacy_statement.secure_info_p4", { ns: "contact" })}</p>

      {/* Right to Deletion */}
      <Header as="h3">
        {t("privacy_statement.right_to_delete", { ns: "contact" })}
      </Header>
      <p>{t("privacy_statement.right_to_delete_p1", { ns: "contact" })}</p>
      <List>
        <List.Item
          icon="check"
          content={t("privacy_statement.right_to_delete_p2", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.right_to_delete_p3", { ns: "contact" })}
        />
      </List>
      <p>{t("privacy_statement.right_to_delete_p4", { ns: "contact" })}</p>
      <List>
        <List.Item
          icon="check"
          content={t("privacy_statement.right_to_delete_p5", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.right_to_delete_p6", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.right_to_delete_p7", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.right_to_delete_p8", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.right_to_delete_p9", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.right_to_delete_p10", {
            ns: "contact",
          })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.right_to_delete_p11", {
            ns: "contact",
          })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.right_to_delete_p12", {
            ns: "contact",
          })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.right_to_delete_p13", {
            ns: "contact",
          })}
        />
      </List>

      {/* Children Under Thirteen  */}
      <Header as="h3">{t("privacy_statement.kids", { ns: "contact" })}</Header>
      <p>{t("privacy_statement.kids_p1", { ns: "contact" })}</p>

      {/* Opt-Out & Unsubscribe from Third Party Communications */}
      <Header as="h3">
        {t("privacy_statement.unsubscribe", { ns: "contact" })}
      </Header>
      <p>{t("privacy_statement.unsubscribe_p1", { ns: "contact" })}</p>
      <List>
        <List.Item
          icon="check"
          content={t("privacy_statement.unsubscribe_p2", { ns: "contact" })}
        />
        <List.Item
          icon="check"
          content={t("privacy_statement.unsubscribe_p3", { ns: "contact" })}
        />
      </List>

      {/* E-mail Communications */}
      <Header as="h3">
        {t("privacy_statement.email_communications", { ns: "contact" })}
      </Header>
      <p>{t("privacy_statement.email_communications_p1", { ns: "contact" })}</p>
      <p>{t("privacy_statement.email_communications_p2", { ns: "contact" })}</p>

      {/* External Data Storage Sites */}
      <Header as="h3">
        {t("privacy_statement.external_data", { ns: "contact" })}
      </Header>
      <p>{t("privacy_statement.external_data_p1", { ns: "contact" })}</p>

      {/* Information Process in the United States */}
      <Header as="h3">
        {t("privacy_statement.info_process", { ns: "contact" })}
      </Header>
      <p>{t("privacy_statement.info_process_p1", { ns: "contact" })}</p>
      <p>{t("privacy_statement.info_process_p2", { ns: "contact" })}</p>

      {/* Changes to this Statement */}
      <Header as="h3">
        {t("privacy_statement.policy_change", { ns: "contact" })}
      </Header>
      <p>{t("privacy_statement.policy_change_p1", { ns: "contact" })}</p>

      <Header as="h3">
        {t("privacy_statement.contact", { ns: "contact" })}
      </Header>
      <p>{t("privacy_statement.contact_p1", { ns: "contact" })}</p>
      <List>
        <List.Item
          content={t("privacy_statement.contact_p2", { ns: "contact" })}
        />
        <List.Item
          content={t("privacy_statement.contact_p3", { ns: "contact" })}
        />
        <List.Item
          content={t("privacy_statement.contact_p4", { ns: "contact" })}
        />
      </List>

      <List>
        <List.Item
          content={t("privacy_statement.contact_p5", { ns: "contact" })}
        />
        <List.Item
          content={t("privacy_statement.contact_p6", { ns: "contact" })}
        />
      </List>
      <p>{t("privacy_statement.effective_date", { ns: "contact" })}</p>
    </Container>
  );
}
