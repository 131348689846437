// import { createMedia } from '@artsy/fresnel'
import React from "react";
import {
  List,
  Container,
  Grid,
  Header,
  Image,
  Segment,
  CardGroup,
  Card,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import ComparisonCards from "./ComparisonCards";
import "./homepageLayout.css";
import background from "./img/backgroundImage1.webp";
import HomepageHeading from "../../features/home/HomepageHeading";
import wrench from "./img/wrench.webp";

// const { MediaContextProvider, Media } = createMedia({
//   breakpoints: {
//     mobile: 0,
//     tablet: 768,
//     computer: 1024,
//   },
// });

export default function HomepageLayout() {
  const { t } = useTranslation(["common", "users", "frontPage"]);

  return (
    <>
      <Segment
        className="homepage_container"
        style={{
          backgroundImage: `url("${background}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        vertical
      >
        <HomepageHeading />
      </Segment>
      <Container>
        <Segment className="segment1_homepage" basic>
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as="h3" className="homepage_header_font">
                  {t("intro_header", {
                    ns: "frontPage",
                  })}
                </Header>
                <p className="homepage_text">
                  {t("intro_paragraph", {
                    ns: "frontPage",
                  })}
                </p>
                <Header as="h3" className="homepage_header_font">
                  {t("what_we_do", {
                    ns: "frontPage",
                  })}
                </Header>
                <List relaxed="very" className="homepage_text">
                  <List.Item
                    content={t("what_we_do_list.accuracy", {
                      ns: "frontPage",
                    })}
                  />
                  <List.Item
                    content={t("what_we_do_list.price", {
                      ns: "frontPage",
                    })}
                  />
                  <List.Item
                    content={t("what_we_do_list.easy_use", {
                      ns: "frontPage",
                    })}
                  />
                </List>
              </Grid.Column>
              <Grid.Column floated="right" width={6}>
                <Image bordered rounded size="large" src={wrench} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment basic className="flipCard">
          <ComparisonCards />
        </Segment>

        <Segment basic>
          <Container className="exceptions">
            <p className="exception">
              {t("exceptions.ae", {
                ns: "frontPage",
              })}
            </p>
            <p className="exception">
              {t("exceptions.monthly", {
                ns: "frontPage",
              })}
            </p>
            <p className="exception">
              {t("exceptions.yearly", {
                ns: "frontPage",
              })}
            </p>
          </Container>
        </Segment>

        <Segment style={{ padding: "8em 0em" }} vertical>
          <Container text>
            <Header as="h3" className="homepage_header_font">
              {t("real_mechanics", {
                ns: "frontPage",
              })}
            </Header>
            <p className="homepage_text">
              {t("real_paragraph", {
                ns: "frontPage",
              })}
            </p>
          </Container>
        </Segment>

        <Segment style={{ padding: "8em 0em" }} basic>
          <Grid container stackable verticalAlign="middle" textAlign="center">
            <Header as="h3" className="homepage_header_font">
              {t("review", {
                ns: "frontPage",
              })}
            </Header>
            <Grid.Row>
              <CardGroup centered>
                <Card>
                  <Card.Content className="review_cards">
                    <Card.Header>Thomas</Card.Header>
                    <Card.Meta>Center Heights Service Inc.</Card.Meta>
                    <Card.Description>
                      "Your service is very simple to use and manage. Work
                      orders can be written fast and accurate. Reports are
                      equally as easy to work with.”
                    </Card.Description>
                  </Card.Content>
                </Card>
                <Card>
                  <Card.Content className="review_cards">
                    <Card.Header>Chris</Card.Header>
                    <Card.Meta>Smith's Auto Repair & Detail</Card.Meta>
                    <Card.Description>
                      "Automotive Expert has saved me time and money on
                      inventory and stock and helps me keep up with my
                      income…Thanks again!”
                    </Card.Description>
                  </Card.Content>
                </Card>
                <Card>
                  <Card.Content className="review_cards">
                    <Card.Header>Yvon</Card.Header>
                    <Card.Meta>W.SELMI GARAGE</Card.Meta>
                    <Card.Description>
                      "I been in this trade for more then 35 years and this is
                      the most easy system I use...super excellent ”
                    </Card.Description>
                  </Card.Content>
                </Card>
              </CardGroup>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    </>
  );
}
