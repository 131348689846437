import React, { useEffect } from "react";
import {
  Container,
  Card,
  Header,
  Divider,
  List,
  Grid,
  Segment,
  Button,
  Image,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./contact.css";
import FAQAccordion from "./FAQAccordion";
import pic2 from "./img/RTP5.webp";
import pic1 from "./img/RTLG1.webp";
import pic3 from "./img/RTP1.webp";
import RTP from "./img/RTP_card2.webp";
import RTLG from "./img/RTLG_card2.webp";
import AE from "./img/AE_card2.webp";
import MacRemoteAssistantDownload from "../../features/download/A94F76FF-4864-42E2-A22D-B13C8D845B3F/MRS.dmg";
import WindowsRemoteAssistantDownload from "../../features/download/A94F76FF-4864-42E2-A22D-B13C8D845B3F/WRS.exe";

export default function FAQs() {
  const { t } = useTranslation(["common", "users", "contact"]);

  useEffect(() => {
    document.title = t("FAQs.header", {
      ns: "contact",
    });
  }, []);

  return (
    <>
      <Container className="product-container">
        <Header as="h1" className="FAQ_header_font">
          {t("FAQs.header", {
            ns: "contact",
          })}
        </Header>
        <Header as="h3">
          {t("FAQs.subheader", {
            ns: "contact",
          })}
          <a href="/ContactUs">
            {t("FAQs.contact", {
              ns: "contact",
            })}
          </a>
        </Header>
        <Divider />
        <Header as="h1" className="comparison_FAQs">
          {t("FAQs.differences", {
            ns: "contact",
          })}
        </Header>
        <Container className="FAQ_comparison_cards">
          <Card.Group centered>
            <Card
              className="card1"
              style={{
                backgroundImage: `url("${RTLG}")`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Card.Content className="card1">
                <Card.Header className="card1">
                  {t("FAQs.rtlg", {
                    ns: "contact",
                  }) + ":"}
                </Card.Header>
                <Card.Description className="card1">
                  {t("FAQs.rtlg_product_info", {
                    ns: "contact",
                  })}
                </Card.Description>
              </Card.Content>
            </Card>
            <Card
              className="card2"
              style={{
                backgroundImage: `url("${RTP}")`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Card.Content className="card2">
                <Card.Header className="card2">
                  {t("FAQs.rtp", {
                    ns: "contact",
                  }) + ":"}
                </Card.Header>
                <Card.Description className="card2">
                  {t("FAQs.rtp_product_info", {
                    ns: "contact",
                  })}
                </Card.Description>
              </Card.Content>
            </Card>
            <Card
              className="card3"
              style={{
                backgroundImage: `url("${AE}")`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Card.Content className="card3">
                <Card.Header className="card3">
                  {t("FAQs.ae", {
                    ns: "contact",
                  }) + ":"}
                </Card.Header>
                <Card.Description className="card3">
                  {t("FAQs.ae_product_info", {
                    ns: "contact",
                  })}
                </Card.Description>
              </Card.Content>
            </Card>
          </Card.Group>
        </Container>
        <Segment basic className="FAQ_pt2">
          <Header textAlign="center" as="h1" className="homepage_header_font">
            {t("FAQs.pricing_guide_intro", {
              ns: "contact",
            }) + ":"}
          </Header>
          <p className="homepage_text">
            {t("FAQs.pricing_guide_p1", {
              ns: "contact",
            })}
          </p>
          <p className="homepage_text">
            {t("FAQs.pricing_guide_p2", {
              ns: "contact",
            })}
          </p>
          <List bulleted>
            <List.Item>
              <p className="homepage_text">
                {t("FAQs.pricing_guide_p3", {
                  ns: "contact",
                })}
              </p>
            </List.Item>
            <List.Item>
              <p className="homepage_text">
                {t("FAQs.pricing_guide_p4", {
                  ns: "contact",
                })}
              </p>
            </List.Item>
            <List.Item>
              <p className="homepage_text">
                {t("FAQs.pricing_guide_p5", {
                  ns: "contact",
                })}
              </p>
            </List.Item>
            <List.Item>
              <p className="homepage_text">
                {t("FAQs.pricing_guide_p6", {
                  ns: "contact",
                })}
              </p>
            </List.Item>
          </List>
          <p className="homepage_text">
            {t("FAQs.pricing_guide_p7", {
              ns: "contact",
            })}
          </p>
        </Segment>
        <Segment basic className="FAQ_pt2">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as="h3" className="homepage_header_font">
                  {t("FAQs.labor_times_header", {
                    ns: "contact",
                  })}
                </Header>
                <p className="homepage_text">
                  {t("FAQs.labor_times_p1", {
                    ns: "contact",
                  })}
                </p>
                <Header as="h3" className="homepage_header_font">
                  {t("FAQs.better_guide_intro", {
                    ns: "contact",
                  })}
                </Header>
                <p className="homepage_text">
                  {t("FAQs.better_guide_p1", {
                    ns: "contact",
                  })}
                </p>
                <p className="homepage_text">
                  {t("FAQs.better_guide_p2", {
                    ns: "contact",
                  })}
                </p>
              </Grid.Column>
              <Grid.Column floated="right" textAlign="right" width={6}>
                <Image bordered rounded fluid src={pic1} />
                <Header as="h3" className="homepage_header_font">
                  {t("FAQs.more_qs", {
                    ns: "contact",
                  }) + "?"}
                </Header>
                <Button
                  href="/contactUs"
                  className="RTLG_button header_button"
                  primary
                  content={
                    t("FAQs.contact_us", {
                      ns: "contact",
                    }) + "!"
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment basic className="FAQ_pt3">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={6} textAlign="left">
                <Image bordered rounded fluid src={pic2} />
                <Header as="h3" className="homepage_header_font">
                  {t("FAQs.need_help", {
                    ns: "contact",
                  }) + "?"}
                </Header>
                <Button
                  href="/contactUs"
                  className="RTLG_button header_button"
                  primary
                  content={
                    t("FAQs.contact_us", {
                      ns: "contact",
                    }) + "!"
                  }
                />
              </Grid.Column>
              <Grid.Column floated="right" width={8}>
                <Header as="h3" className="homepage_header_font">
                  {t("FAQs.included_vehicles_header", {
                    ns: "contact",
                  })}
                </Header>
                <p className="homepage_text">
                  {t("FAQs.included_vehicles_p1", {
                    ns: "contact",
                  })}
                </p>
                <Header as="h3" className="homepage_header_font">
                  {t("FAQs.select_times", {
                    ns: "contact",
                  })}
                </Header>
                <p className="homepage_text">
                  {t("FAQs.select_times_p1", {
                    ns: "contact",
                  })}
                </p>
                <Header as="h3" className="homepage_header_font">
                  {t("FAQs.cd_header", {
                    ns: "contact",
                  })}
                </Header>
                <p className="homepage_text">
                  {t("FAQs.cd_p1", {
                    ns: "contact",
                  })}
                </p>
                <p className="homepage_text">
                  {t("FAQs.cd_p2", {
                    ns: "contact",
                  })}
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment basic className="FAQ_pt3">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as="h3" className="homepage_header_font">
                  {t("FAQs.tax_exempt", {
                    ns: "contact",
                  })}
                </Header>
                <p className="homepage_text">
                  {t("FAQs.tax_exempt_p1", {
                    ns: "contact",
                  })}
                </p>
                <Header as="h3" className="homepage_header_font">
                  {t("FAQs.times_header", {
                    ns: "contact",
                  })}
                </Header>
                <p className="homepage_text">
                  {t("FAQs.times", {
                    ns: "contact",
                  })}
                </p>
                <Header as="h3" className="homepage_header_font">
                  {t("FAQs.corrections", {
                    ns: "contact",
                  })}
                </Header>
                <p className="homepage_text">
                  {t("FAQs.corrections_p1", {
                    ns: "contact",
                  })}
                  <a href="/CorrectionsForm">
                    {t("FAQs.corrections_link", {
                      ns: "contact",
                    })}
                  </a>
                  {t("FAQs.corrections_p3", {
                    ns: "contact",
                  })}
                </p>
                <Header as="h3" className="homepage_header_font">
                  {t("FAQs.free_trial_intro", {
                    ns: "contact",
                  }) + "?"}
                </Header>
                <p className="homepage_text">
                  {t("FAQs.free_trial", {
                    ns: "contact",
                  })}
                </p>
              </Grid.Column>
              <Grid.Column floated="right" textAlign="right" width={6}>
                <Image bordered rounded fluid src={pic3} />
                <Header as="h3" className="homepage_header_font">
                  {t("FAQs.not_seeing", {
                    ns: "contact",
                  }) + "?"}
                </Header>
                <Button
                  href="/contactUs"
                  className="RTLG_button header_button"
                  primary
                  content={
                    t("FAQs.contact_us", {
                      ns: "contact",
                    }) + "!"
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <FAQAccordion />
        <Segment basic>
          <Header textAlign="center" as="h1" className="homepage_header_font">
            {t("FAQs.add_support_intro", {
              ns: "contact",
            }) + "?"}
          </Header>
          <p className="homepage_text">
            {t("FAQs.add_support_p1", {
              ns: "contact",
            })}
            <a href="tel:8004870279">(800)487-0279</a>
            {t("FAQs.add_support_p2", {
              ns: "contact",
            })}
          </p>
          <p className="homepage_text">
            {t("FAQs.add_support_p3", {
              ns: "contact",
            })}
          </p>
          <p className="homepage_text">
            {t("FAQs.add_support_p4", {
              ns: "contact",
            })}
          </p>
          <Button
            primary
            className="RTLG_button"
            href={
              window.location.protocol +
              "//" +
              window.location.hostname +
              process.env.REACT_APP_PORT +
              "/download/3B387E40-1F21-4DEF-8F40-A2A19DBB402F?productID=FD1684DE-7C46-4494-B5B8-F1F279A0158A"
            }
          >
            {t("FAQs.dl_link_RTLG", {
              ns: "contact",
            })}
          </Button>
          <Button
            primary
            className="AE_button"
            href={
              window.location.protocol +
              "//" +
              window.location.hostname +
              process.env.REACT_APP_PORT +
              "/download/3B387E40-1F21-4DEF-8F40-A2A19DBB402F?productID=5735E7F4-7827-4293-82F5-46D238ED8582"
            }
          >
            {t("FAQs.dl_link_AE", {
              ns: "contact",
            })}
          </Button>
          <a
            href={MacRemoteAssistantDownload}
            download={t("MRA.Name", { ns: "download" }) + ".dmg"}
            target="_blank"
          >
            <Button color="black" floated="right">
              {t("FAQs.remote_supp_mac", {
                ns: "contact",
              })}
            </Button>
          </a>
          <a
            href={WindowsRemoteAssistantDownload}
            download={t("WRA.Name", { ns: "download" }) + ".exe"}
            target="_blank"
          >
            <Button color="black" floated="right">
              {t("FAQs.remote_supp_wind", {
                ns: "contact",
              })}
            </Button>
          </a>
          <Button
            color="black"
            floated="right"
            href={
              window.location.protocol +
              "//" +
              window.location.hostname +
              process.env.REACT_APP_PORT +
              "/download/3B387E40-1F21-4DEF-8F40-A2A19DBB402F?productID=07fc6381-3506-4215-9309-fde4208da461"
            }
          >
            {t("FAQs.formatted_for_legal", {
              ns: "contact",
            })}
          </Button>
        </Segment>
      </Container>
    </>
  );
}
