import React, { useEffect } from "react";
import { Container, Header, Divider, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./download.css";

import Protect from "react-app-protect";
import "react-app-protect/dist/index.css";
import { useLocation } from "react-router-dom";
import AEDownload from "../../features/download/A94F76FF-4864-42E2-A22D-B13C8D845B3F/AE1141.exe";
import RTLGDownload from "../../features/download/A94F76FF-4864-42E2-A22D-B13C8D845B3F/RTLG1041.exe";
import FFLDownload from "../../features/download/A94F76FF-4864-42E2-A22D-B13C8D845B3F/FFL.exe";
import MacRemoteAssistantDownload from "../../features/download/A94F76FF-4864-42E2-A22D-B13C8D845B3F/MRS.dmg";
import WindowsRemoteAssistantDownload from "../../features/download/A94F76FF-4864-42E2-A22D-B13C8D845B3F/WRS.exe";

export default function Download() {
  const { t } = useTranslation(["common", "users", "frontPage", "download"]);

  useEffect(() => {
    document.title = t(product + ".Name", {
      ns: "download",
    });
  }, []);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  var product = "";
  var download = "";
  var type = "";

  switch (params.get("productID")) {
    case "5735E7F4-7827-4293-82F5-46D238ED8582":
      product = "AE";
      download = AEDownload;
      type = ".exe";
      break;
    case "FD1684DE-7C46-4494-B5B8-F1F279A0158A":
      product = "RTLG";
      download = RTLGDownload;
      type = ".exe";
      break;
    case "07fc6381-3506-4215-9309-fde4208da461":
      product = "FFL";
      download = FFLDownload;
      type = ".exe";
      break;
    case "898713a8-89ed-444f-a1c8-63d4292e8d3f":
      product = "MRA";
      download = MacRemoteAssistantDownload;
      type = ".dmg";
      break;
    case "6795a176-9e93-473b-bd0e-426474feb229":
      product = "WRA";
      download = WindowsRemoteAssistantDownload;
      type = ".exe";
      break;
    default:
      window.location.href = "http://localhost:3000/";
  }

  return (
    <Container className="download-container">
      <Protect
        // to set the password use this website https://sha512.online/
        sha512="9b6c6276d36d3d8394d156b5fa21e4b2d809929511dc1fb51d811e3ca6915e6890badb6bc9054768c4536daadcda4f132d25548726ed9f1eed341f44edef118d"
        boxTitle={t(product + ".Protect_Title", { ns: "download" })}
        blur
      >
        <>
          <Header as="h1" className="FAQ_header_font">
            {t(product + ".Name", {
              ns: "download",
            })}
          </Header>
          <Header as="h3">
            {t(product + ".Download_Title", {
              ns: "download",
            })}
          </Header>

          <Divider />

          <a
            href={download}
            download={t(product + ".Executible", { ns: "download" }) + type}
            target="_blank"
          >
            <Button primary>
              {t(product + ".p2", {
                ns: "download",
              })}
            </Button>
          </a>
        </>
      </Protect>
    </Container>
  );
}
