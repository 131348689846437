import React, { useEffect } from "react";
import {
  Container,
  Header,
  Form,
  Segment,
  Divider,
  Label,
  Grid,
  Select,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./products.css";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import * as Regex from "../regex.js";
import background from "./img/formBG.webp";
import { useHistory } from "react-router-dom";

export default function AEform() {
  const { t } = useTranslation(["common", "users", "frontPage", "products"]);

  useEffect(() => {
    document.title = t("AE_Form.header", {
      ns: "products",
    });
  }, []);

  const history = useHistory();

  const countryOptions = [
    { value: "US", text: "United States", key: "US" },
    { value: "CN", text: "Canada", key: "CN" },
  ];

  const hearAboutusOptions = [
    { text: "Google Search", value: "Google Search", key: "Google Search" },
    { text: "Bing Search", value: "Bing Search", key: "Bing Search" },
    { text: "Yahoo Search", value: "Yahoo Search", key: "Yahoo Search" },
    { text: "Other Search", value: "Other Search", key: "Other Search" },
    { text: "From a Friend", value: "From a Friend", key: "From a Friend" },
    {
      text: "Trade Show / Class",
      value: "Trade Show / Class",
      key: "Trade Show / Class",
    },
    { text: "Motor Magazine", value: "Motor Magazine", key: "Motor Magazine" },
    {
      text: "Automotive Management Network",
      value: "Automotive Management Network",
      key: "Automotive Management Network",
    },
    { text: "Other", value: "Other", key: "other" },
  ];

  const mydata = {
    companyName: "",
    contactName: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    phoneNumber: "",
    email: "",
    hearAboutUs: "",
    other: "",
  };

  const formik = useFormik({
    initialValues: { mydata },
    validationSchema: Yup.object({
      companyName: Yup.string().required(
        t("FreeTrial.companyName", { ns: "errors" })
      ),
      contactName: Yup.string().required(
        t("FreeTrial.fullName", { ns: "errors" })
      ),
      address: Yup.string().required(
        t("FreeTrial.addressLine1", { ns: "errors" })
      ),
      city: Yup.string().required(t("FreeTrial.city", { ns: "errors" })),
      state: Yup.string().required(t("FreeTrial.state", { ns: "errors" })),
      postalCode: Yup.string().required(
        t("FreeTrial.postalCode", { ns: "errors" })
      ),
      country: Yup.string().required(t("FreeTrial.country", { ns: "errors" })),
      hearAboutUs: Yup.string().required(
        t("FreeTrial.hearAboutUs", { ns: "errors" })
      ),
      other: Yup.string(),
      phoneNumber: Yup.string()
        .matches(
          Regex.PhoneNumberMatch,
          t("ContactUs.invalid_PhoneNumber", { ns: "errors" })
        )
        .required(t("ContactUs.required_PhoneNumber", { ns: "errors" })),
      email: Yup.string()
        .matches(
          Regex.EmailMatch,
          t("ContactUs.invalid_Email", { ns: "errors" })
        )
        .required(t("Corrections.required_emailAddress", { ns: "errors" })),
    }),
    onSubmit: (values) => {
      axios({
        method: "POST",
        headers: { "content-type": "application/json" },
        //url: window.location.protocol + process.env.REACT_APP_API_URL + "/DSIMarketing/freetrialrequest",
        url: process.env.REACT_APP_API_URL + "/DSIMarketing/freetrialrequest",
        data: values,
      }).then((response) => {
        history.push("/AutomotiveExpert/FreeTrial/AEformConfirm");
      });
    },
  });

  return (
    <>
      <Segment
        className="staticBG"
        style={{
          backgroundImage: `url("${background}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container className="contact-container overflow-auto overflow_form">
          <Container className="form_frost">
            <Header as="h1" className="FAQ_header_font">
              {t("AE_Form.header", {
                ns: "products",
              })}
            </Header>
            <Segment basic>
              <p>
                {t("AE_Form.p1", {
                  ns: "products",
                })}
              </p>
              <p>
                {t("AE_Form.note", {
                  ns: "products",
                })}
              </p>
            </Segment>
            <Divider />

            {/* <Header as="h4" className="times">
          {t("AE_Form.addy", {
            ns: "products",
          }) + ":"}
        </Header> */}
            <Container>
              <Form onSubmit={formik.handleSubmit}>
                <Grid stackable columns={2}>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input
                        name="companyName"
                        onChange={formik.handleChange}
                        values={formik.values.companyName}
                        label={
                          t("AE_Form.shop_name", {
                            ns: "products",
                          }) + ":"
                        }
                        placeholder={t("AE_Form.shop_name", {
                          ns: "products",
                        })}
                      />
                      {formik.errors.companyName ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.companyName ? (
                              <Label basic color="red">
                                {formik.errors.companyName}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        name="contactName"
                        onChange={formik.handleChange}
                        values={formik.values.contactName}
                        label={
                          t("AE_Form.name", {
                            ns: "products",
                          }) + ":"
                        }
                        placeholder={t("AE_Form.name", {
                          ns: "products",
                        })}
                      />
                      {formik.errors.contactName ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.contactName ? (
                              <Label basic color="red">
                                {formik.errors.contactName}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                      <Form.Input
                        name="address"
                        onChange={formik.handleChange}
                        values={formik.values.address}
                        label={
                          t("AE_Form.shop_address", {
                            ns: "products",
                          }) + ":"
                        }
                        placeholder={t("AE_Form.shop_address", {
                          ns: "products",
                        })}
                      />
                      {formik.errors.address ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.address ? (
                              <Label basic color="red">
                                {formik.errors.address}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input
                        name="city"
                        onChange={formik.handleChange}
                        values={formik.values.city}
                        label={
                          t("AE_Form.city", {
                            ns: "products",
                          }) + ":"
                        }
                        placeholder={t("AE_Form.city", {
                          ns: "products",
                        })}
                      />
                      {formik.errors.city ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.city ? (
                              <Label basic color="red">
                                {formik.errors.city}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        name="state"
                        onChange={formik.handleChange}
                        values={formik.values.state}
                        label={
                          t("AE_Form.state", {
                            ns: "products",
                          }) + ":"
                        }
                        placeholder={t("AE_Form.state", {
                          ns: "products",
                        })}
                      />
                      {formik.errors.state ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.state ? (
                              <Label basic color="red">
                                {formik.errors.state}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input
                        name="postalCode"
                        onChange={formik.handleChange}
                        values={formik.values.postalCode}
                        label={
                          t("AE_Form.zip", {
                            ns: "products",
                          }) + ":"
                        }
                        placeholder={t("AE_Form.zip", {
                          ns: "products",
                        })}
                      />
                      {formik.errors.postalCode ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.postalCode ? (
                              <Label basic color="red">
                                {formik.errors.postalCode}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>
                          {t("AE_Form.country", { ns: "products" }) + ":"}{" "}
                        </label>
                        <Select
                          //defaultValue="US"
                          name="country"
                          options={countryOptions}
                          label={t("AE_Form.country", { ns: "products" }) + ":"}
                          values={formik.values.country}
                          onChange={(e, data) => {
                            formik.handleChange(e);
                            formik.setFieldValue("country", data.value);
                          }}
                          placeholder="Select your country"
                        />
                      </Form.Field>
                      {formik.errors.country ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.country ? (
                              <Label basic color="red">
                                {formik.errors.country}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input
                        name="phoneNumber"
                        onChange={formik.handleChange}
                        values={formik.values.phoneNumber}
                        label={
                          t("AE_Form.phone", {
                            ns: "products",
                          }) + ":"
                        }
                        placeholder={t("AE_Form.phone", {
                          ns: "products",
                        })}
                      />
                      {formik.errors.phoneNumber ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.phoneNumber ? (
                              <Label basic color="red">
                                {formik.errors.phoneNumber}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        name="email"
                        onChange={formik.handleChange}
                        values={formik.values.email}
                        label={
                          t("AE_Form.email", {
                            ns: "products",
                          }) + ":"
                        }
                        placeholder={t("AE_Form.email", {
                          ns: "products",
                        })}
                      />
                      {formik.errors.email ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.email ? (
                              <Label basic color="red">
                                {formik.errors.email}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Field>
                        <label>
                          {t("AE_Form.refer", { ns: "products" }) + ":"}{" "}
                        </label>
                        <Select
                          // defaultValue="Google Search"
                          name="hearAboutUs"
                          options={hearAboutusOptions}
                          label={t("AE_Form.refer", { ns: "products" })}
                          values={formik.values.hearAboutUs}
                          onChange={(e, data) => {
                            formik.handleChange(e);
                            formik.setFieldValue("hearAboutUs", data.value);
                          }}
                          placeholder="Select How did you hear about us?"
                        />
                      </Form.Field>
                      {formik.errors.hearAboutUs ? (
                        <div className="row mb-2">
                          <div className="col">
                            {formik.errors.hearAboutUs ? (
                              <Label basic color="red">
                                {formik.errors.hearAboutUs}
                              </Label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        name="other"
                        onChange={formik.handleChange}
                        values={formik.values.other}
                        label={
                          t("AE_Form.other", {
                            ns: "products",
                          }) + ":"
                        }
                        placeholder={t("AE_Form.other", {
                          ns: "products",
                        })}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Form.Button color="grey">
                      {t("AE_Form.request_button", {
                        ns: "products",
                      })}
                    </Form.Button>
                  </Grid.Row>
                </Grid>
              </Form>
            </Container>
          </Container>
        </Container>
      </Segment>
    </>
  );
}
