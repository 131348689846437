import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Header, Segment, Button } from "semantic-ui-react";
import "./errors.css";
import ErrorBackground from "./img/404.webp";

export default function FourOhFour() {

  const {t} = useTranslation(["errors"]);

  useEffect(() => {
    document.title = t("404", {
      ns: "errors",
    });
  }, []);

  return (
    <>
      <Segment
        className="staticBG"
        style={{
          backgroundImage: `url("${ErrorBackground}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container className="contact-container overflow-auto overflow_form">
          <Container textAlign="center" className="form_frost confirmations">
            <Header textAlign="center" as="h2">
              {t("404", {
                ns: "errors",
              }) + ":"}
            </Header>
            <Button
              color="grey"
              href="/"
              content={t("home_button", {
                ns: "errors",
              })}
            />
          </Container>
        </Container>
      </Segment>
    </>
  );
}
