import React from "react";
import {
  Container,
  Grid,
  Header,
  List,
  Segment,
  Icon,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function Footer() {

  const { t } = useTranslation(['common', "users", "frontPage"]);

  return (
    <Segment inverted vertical className="footer">
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as="h4" content={t("footer.about", {ns: "frontPage"})} />
              <List link inverted>
                <List.Item href='/ContactUs'>{t("footer.contact", {ns: "frontPage"})}</List.Item>
                <List.Item href='/FrequentlyAskedQuestions'>{t("footer.faq", {ns: "frontPage"})}</List.Item>
                {/* <List.Item href='/AboutUs'>{t("footer.about_us", {ns: "frontPage"})}</List.Item> */}
                <List.Item href='/PrivacyStatement'>{t("footer.privacy", {ns: "frontPage"})}</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as="h4" content={t("footer.services", {ns: "frontPage"})} />
              <List link inverted>
                <List.Item href="/Real-TimeLaborGuide">{t("footer.rtlg", {ns: "frontPage"})}</List.Item>
                <List.Item href="/Real-TimePro">{t("footer.rtp", {ns: "frontPage"})}</List.Item>
                <List.Item href="/AutomotiveExpert">{t("footer.ae", {ns: "frontPage"})}</List.Item>
                <List.Item href='/CorrectionsForm'>{t("footer.corrections", {ns: "frontPage"})}</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as="h4" inverted>
              {t("footer.contact", {ns: "frontPage"})}
              </Header>
              <List link inverted>
              <p>
              {t("footer.rtlg", {ns: "frontPage"})} <br></br>
              <List.Item href="tel:8004870279">{t("address.phone", {ns: "frontPage"})} </List.Item><br></br>
              {t("address.addy1", {ns: "frontPage"})} <br></br>
              {t("address.addy2", {ns: "frontPage"})} <br></br>
              </p>
              </List>
              <Segment inverted> 
              <a className="icons" href="https://www.facebook.com/laborguide" rel="noreferrer noopener" target="_blank">
                <Icon inverted color='grey' size="big" link name="facebook" />
                </a>
                <a className="icons" href="https://www.instagram.com/realtimelaborguide/" rel="noreferrer noopener" target="_blank">
                <Icon inverted color='grey' size="big" link name="instagram" />
                </a>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
}
