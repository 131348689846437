import React, { Component } from "react";
import {
  Container,
  Accordion,
  Icon,
  List,
  Card
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";

class AEAccordion extends Component {
  state = { activeIndex: -1 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { t } = this.props;
    const { activeIndex } = this.state;

    return (
      <Container className="product-container">
        <Accordion>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
            as="h3"
            className="homepage_header_font"
          >
            <Icon name="dropdown" />
            {t("AE.AE_cd_intro", {
              ns: "products",
            }) + "?"}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
           <Card.Group centered>
            <Card>
                <Card.Content>
                    <Card.Header>
                    {t("AE.need_cd_intro", {
                      ns: "products",
                    })}
                    </Card.Header>
                    <Card.Description>
                    {t("AE.need_cd", {
                      ns: "products",
                    })}
                    </Card.Description>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <Card.Header>
                    {t("AE.lose_cd_intro", {
                      ns: "products",
                    })}
                    </Card.Header>
                    <Card.Description>
                    {t("AE.lose_cd", {
                      ns: "products",
                    })}
                    </Card.Description>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <Card.Header>
                    {t("AE.loan_cd_intro", {
                      ns: "products",
                    })}
                    </Card.Header>
                    <Card.Description>
                    {t("AE.loan_cd", {
                      ns: "products",
                    })}
                    </Card.Description>
                </Card.Content>
            </Card>
            <Card>
                <Card.Content>
                    <Card.Header>
                    {t("AE.backup_intro", {
                      ns: "products",
                    })}
                    </Card.Header>
                    <Card.Description>
                    <p>{t("AE.backup_p1", {
                      ns: "products",
                    })}</p>
                    <List ordered>
                      <List.Item>{t("AE.backup_p2", {
                      ns: "products",
                    })}</List.Item>
                    <List.Item>{t("AE.backup_p3", {
                      ns: "products",
                    })}</List.Item>
                    <List.Item>{t("AE.backup_p4", {
                      ns: "products",
                    })}</List.Item>

                    </List>
                    </Card.Description>
                </Card.Content>
            </Card>
           </Card.Group>
          </Accordion.Content>

        </Accordion>
      </Container>
    );
  }
}

export default withTranslation()(AEAccordion);
