// import { createMedia } from "@artsy/fresnel";
import React, { useEffect } from "react";
import HomepageLayout from "./HomepageLayout";


// const { MediaContextProvider, Media } = createMedia({
//   breakpoints: {
//     mobile: 0,
//     tablet: 768,
//     computer: 1024,
//   },
// });




export default function HomePage() {
  
  useEffect(() => {
    document.title = "Homepage";
  }, []);

  return (
    <>
      <HomepageLayout />
    </>
  );
}
