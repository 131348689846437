import { useState } from "react";
import cn from "classnames";
import { ReplyFill as Icon } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import {Link} from "react-router-dom";

export default function FlipCard({ card: { id, variant, front, back, backgroundColor } }) {
  const [showBack] = useState(false);

  return (
    <div
      tabIndex={id}
      className={cn("flip-card-outer", { hover: variant === "hover" })}
    >
      <div className={cn("flip-card-inner", { showBack })}>
        <div
          className="card front"
          style={{
            backgroundImage: `linear-gradient(#00000000, #00000050), ${front.background}`,
          }}
        >
          <div className="card-body position-relative d-flex justify-content-center align-items-center">
            <div className="frost">
            <img className="logo" src={front.image} height={90} alt="logo" />
            </div>
            <div className="flex-column">
              <div className="card-wording">
                <p className="name">{front.list1}</p>
                <p className="name">{front.list2}</p>
                <p className="name">{front.list3}</p>
                <p className="name">{front.list4}</p>
                <p className="name">{front.list5}</p>
                <p className="name">{front.list6}</p>
                <p className="name">{front.list7}</p>
                </div>
                <p className="price">${front.price}</p>
              <div className="icon">
                <Icon size={35} />
              </div>
            </div>
          </div>
        </div>

        <div className="card back" style={{ backgroundColor: backgroundColor }}>
          <div className="card-body d-flex flex-column justify-content-around align-items-center">
            <img className="logo" src={back.image} height={70}  alt={"logo"}  />
            <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="card-wording">
            <p className="name">{back.list1}</p>
            </div>
              <Button variant="secondary" as={Link} to={back.destination}>Try for Free!</Button>
            </div>
            <div className="icon">
              <Icon size={35} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}